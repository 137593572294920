import { IMedia } from '../models/media.model';
import { relations } from '../config/relations';

export function getMediaWithRelation(
    media: IMedia[],
    relation: number,
    useFallback = true,
    fallback: number = relations.mainImage
): IMedia | undefined {
    let mediaItem = media.find((item) => item.relations.includes(relation));
    if (!mediaItem && useFallback) {
        mediaItem = media.find((item) => item.relations.includes(fallback));
    }
    return mediaItem;
}
