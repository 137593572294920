import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import { crumb, link } from './breadcrumb.module.scss';
import { translationKeys } from '../../config/translation-keys';
import { IBreadcrumb } from '../../models/breadcrumb.model';
import { useTranslation } from '../../hooks/use-translation';

interface IBreadcrumbProps {
    className?: string;
    breadcrumb: IBreadcrumb;
    isLink?: boolean;
}

const Breadcrumb: React.FC<IBreadcrumbProps> = ({ className = '', breadcrumb, isLink = true }) => {
    const { slug } = breadcrumb;
    const { label } = useTranslation(breadcrumb, translationKeys.breadcrumb);
    return isLink ? (
        <Link className={`${crumb} ${link} ${className}`} to={slug} title={label}>
            {label}
        </Link>
    ) : (
        <p className={`${crumb} ${className}`} title={label}>
            {label}
        </p>
    );
};

export default Breadcrumb;
