import React from 'react';

import { container, list, item } from './festival-list.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { IFestival } from '../../models/festival.model';

import FestivalCard from '../molecules/festival-card';

interface IFestivalListProps {
    className?: string;
    festivals: IFestival[];
}

const FestivalList: React.FC<IFestivalListProps> = ({ className = '', festivals }) => {
    return (
        <div className={`${container} ${grid} ${className}`}>
            <ul className={list}>
                {festivals.map((festival) => {
                    return (
                        <li key={festival.festivalId} className={item}>
                            <FestivalCard festival={festival} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default FestivalList;
