import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { container, image, name } from './festival-card.module.scss';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IFestival } from '../../models/festival.model';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';

interface IFestivalCardProps {
    className?: string;
    festival: IFestival;
}

const FestivalCard: React.FC<IFestivalCardProps> = ({ className = '', festival }) => {
    const { media } = festival;
    const { label, url } = useTranslation(festival, translationKeys.festival);
    const mediaItem = useMedia({ media, relation: relations.mainImage });

    const Tag = url ? 'a' : 'div';
    const tagProps = url ? { target: '_blank', rel: 'noreferrer noopener', href: url } : {};

    return (
        <Tag className={`${container} ${className}`} {...tagProps}>
            {mediaItem.image ? (
                <GatsbyImage className={image} alt={mediaItem.alt || ''} image={mediaItem.image} />
            ) : (
                <img className={image} src={mediaItem.url} alt={mediaItem.alt || ''} />
            )}
            <p className={name}>{label}</p>
        </Tag>
    );
};

export default FestivalCard;
