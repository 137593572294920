import { ISiteMetadataTranslation } from '../models/site-metadata.model';
import { ISitePageContextTranslation } from './site-page.model';
import { IPostCardTranslation, IPostTranslation } from '../models/post.model';
import { IAuthorTranslation } from '../models/author.model';
import { IStripeTranslation } from '../models/stripe.model';
import { IPersonTranslation } from '../models/person.model';
import { IFestivalTranslation } from '../models/festival.model';
import { IBreadcrumbTranslation } from '../models/breadcrumb.model';
import { IWorkCardTranslation, IWorkTranslation } from '../models/work.model';
import { IAwardTranslation } from '../models/awards.model';

interface ITranslationKeys {
    author: Array<keyof IAuthorTranslation>;
    award: Array<keyof IAwardTranslation>;
    breadcrumb: Array<keyof IBreadcrumbTranslation>;
    festival: Array<keyof IFestivalTranslation>;
    pageContext: Array<keyof ISitePageContextTranslation>;
    person: Array<keyof IPersonTranslation>;
    post: Array<keyof IPostTranslation>;
    postCard: Array<keyof IPostCardTranslation>;
    workCard: Array<keyof IWorkCardTranslation>;
    siteMetadata: Array<keyof ISiteMetadataTranslation>;
    stripe: Array<keyof IStripeTranslation>;
    work: Array<keyof IWorkTranslation>;
}

export const translationKeys: ITranslationKeys = {
    author: ['firstName', 'lastName'],
    award: ['title'],
    breadcrumb: ['label'],
    festival: ['label', 'url'],
    pageContext: ['slug', 'path', 'label'],
    person: ['firstName', 'lastName', 'title', 'description'],
    post: ['title', 'lead', 'content'],
    postCard: ['title', 'lead'],
    workCard: ['title', 'lead', 'leadLanding', 'clientContent', 'mediaContent', 'videoUrl'],
    siteMetadata: ['defaultTitle', 'defaultDescription'],
    stripe: ['url', 'text'],
    work: [
        'title',
        'lead',
        'content',
        'layoutTitle1',
        'layoutContent1',
        'layoutTitle2',
        'layoutContent2',
        'bottomTitle',
        'subTitle1',
        'subTitle2',
        'subTitle3',
        'subContent1',
        'subContent2',
        'subContent3',
        'videoUrl',
        'clientContent',
        'mediaContent',
    ],
};
