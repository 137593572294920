export interface ISocial {
    url: string;
    socialType: ESocialType;
}

export enum ESocialType {
    facebook = 1,
    instagram = 2,
    linkedin = 3,
    youtube = 4,
}
