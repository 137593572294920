import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { hamburger, lineBox, line, top, middle, bottom, lineDark } from './hamburger.module.scss';

interface IHamburgerProps {
    className?: string;
    onClick(): void;
    isVisibleHeaderBg?: boolean;
    isScrolled?: boolean;
}

const Hamburger: React.FC<IHamburgerProps> = ({
    onClick,
    className = '',
    isVisibleHeaderBg,
    isScrolled,
}) => {
    const { t } = useI18next();
    return (
        <button
            onClick={onClick}
            className={`${className} ${hamburger}`}
            aria-label={t('button.hamburger')}
        >
            <span
                className={`
                ${lineBox} 
                ${isScrolled || isVisibleHeaderBg ? lineDark : ''} 
                `}
            >
                <span className={`${line} ${top}`} />
                <span className={`${line} ${middle} `} />
                <span className={`${line} ${bottom} `} />
            </span>
        </button>
    );
};

export default Hamburger;
