import { IBreadcrumb } from '../models/breadcrumb.model';

interface IObjectToExtractBreadcrumb {
    slug: string;
    pageKey?: string;
    translations: {
        [key: string]: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            [key: string]: any;
        };
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export function getBreadcrumbs(
    items: IObjectToExtractBreadcrumb[],
    labelKey = 'label'
): IBreadcrumb[] {
    if (!items) return [];
    return items.map((item) => {
        return getBreadcrumbItem(item, labelKey);
    });
}

export function getBreadcrumbItem(
    item: IObjectToExtractBreadcrumb,
    labelKey = 'label',
    usePageKey = false
): IBreadcrumb {
    const translations: IBreadcrumb['translations'] = {};
    Object.keys(item.translations).forEach((language) => {
        translations[language] = { label: item.translations[language][labelKey] };
    });
    return {
        slug: usePageKey && item.pageKey ? item.pageKey : item.slug,
        translations,
    };
}
