import React, { CSSProperties } from 'react';
import { GatsbyImage, GatsbyImageProps, IGatsbyImageData } from 'gatsby-plugin-image';

import { ratioContainer, imgBox, img } from './ratio-image.module.scss';

export interface IRatioImageProps {
    className?: string;
    ratioClass?: string;
    image: IGatsbyImageData | undefined;
    fallbackUrl?: string;
    alt?: string;
    objectFit?: CSSProperties['objectFit'];
    objectPosition?: CSSProperties['objectPosition'];
    loading?: GatsbyImageProps['loading'];
}

const RatioImage: React.FC<IRatioImageProps> = ({
    className = '',
    ratioClass = '',
    image,
    fallbackUrl,
    alt = '',
    objectFit = 'cover',
    objectPosition = 'center center',
    loading,
}) => {
    return (
        <div className={className}>
            <div className={`${ratioContainer} ${ratioClass}`}>
                <div className={imgBox}>
                    {image && (
                        <GatsbyImage
                            className={img}
                            image={image}
                            alt={alt || ''}
                            objectFit={objectFit}
                            objectPosition={objectPosition}
                            loading={loading}
                        />
                    )}
                    {!image && fallbackUrl && (
                        <img
                            className={img}
                            src={fallbackUrl}
                            alt={alt || ''}
                            loading={loading}
                            style={{
                                objectFit,
                                objectPosition,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RatioImage;
