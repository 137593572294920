import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import {
    layout,
    layoutLanding,
    marginTopHeader,
    breadcrumbsBox,
    scrollable,
    dark,
    hidden,
    fixedHeight,
} from './main-layout.module.scss';
import { grid } from '../styles/grid.module.scss';
import { IFestival } from '../models/festival.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IBreadcrumb } from '../models/breadcrumb.model';
import { getNodes } from '../utils/get-nodes';
import { useIntroContext } from '../contexts/intro-context';
import useWindowWidth from '../hooks/use-window-width';

import Header from '../components/organisms/header';
import Navigation from '../components/organisms/navigation';
import Footer from '../components/organisms/footer';
import FestivalList from '../components/organisms/festival-list';
import RouteChangeAnimation from '../components/atoms/route-change-animation';
import SEO, { ISEOProps } from '../components/seo';
import Breadcrumbs from '../components/molecules/breadcrumbs';
import Intro from '../components/organisms/intro';
import Cookies from '../components/organisms/cookies';

interface IMainLayoutProps {
    className?: string;
    isVisibleHeaderBg?: boolean;
    SEOProps?: ISEOProps;
    breadcrumbs?: IBreadcrumb[];
    areBreadcrumbsDark?: boolean;
    isLanding?: boolean;
    sectionsToScrollAmount?: number;
}

interface IMainLayoutStaticQueryResult {
    allFestival: IQueryAllResult<IFestival>;
}

const MainLayout: React.FC<IMainLayoutProps> = ({
    className = '',
    children,
    isVisibleHeaderBg = false,
    breadcrumbs,
    areBreadcrumbsDark = false,
    SEOProps,
    isLanding = false,
    sectionsToScrollAmount = 0,
}) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [position, setPosition] = useState(0);
    const { isComplete } = useIntroContext();
    const { allFestival }: IMainLayoutStaticQueryResult = useStaticQuery(query);
    const windowWidth = useWindowWidth();
    const festivals = getNodes(allFestival);

    const handleToggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    useEffect(() => {
        if (windowWidth && windowWidth >= 1200) {
            const handleScroll = (event: WheelEvent | KeyboardEvent) => {
                if (event.type === 'keydown') {
                    handleKeyScroll(event as KeyboardEvent);
                }

                if (event.type === 'wheel') {
                    handleWheelScroll(event as WheelEvent);
                }
            };

            const up = (pos: number) => (pos === 0 ? 0 : pos + 100);
            const down = (pos: number) => (pos === sectionsToScrollAmount * -100 ? pos : pos - 100);

            const handleWheelScroll = (event: WheelEvent) => {
                if (event.deltaY < 0) {
                    setPosition((pos) => up(pos));
                }

                if (event.deltaY > 0) {
                    setPosition((pos) => down(pos));
                }
            };

            const handleKeyScroll = (event: KeyboardEvent) => {
                if (event.key === 'ArrowUp') {
                    setPosition((pos) => up(pos));
                }

                if (event.key === 'ArrowDown') {
                    setPosition((pos) => down(pos));
                }
            };

            const throttledHandleScroll = throttleScroll(handleScroll, 1200);

            window.addEventListener('wheel', throttledHandleScroll);
            window.addEventListener('keydown', throttledHandleScroll);

            return () => {
                window.removeEventListener('wheel', throttledHandleScroll);
                window.removeEventListener('keydown', throttledHandleScroll);
            };
        } else {
            setPosition(0);
        }

        return () => null;
    }, [windowWidth]);

    return (
        <>
            <SEO {...SEOProps} />
            <Helmet>
                <body className={!isComplete ? fixedHeight : ''} />
            </Helmet>
            <div
                className={`${
                    isLanding && windowWidth && windowWidth >= 1200 ? layoutLanding : layout
                } ${isVisibleHeaderBg ? marginTopHeader : ''} ${!isComplete ? hidden : ''}`}
            >
                <Header
                    onClick={handleToggleNav}
                    isVisibleHeaderBg={isVisibleHeaderBg || position < 0}
                    isLanding={isLanding}
                />
                {!isLanding && <Navigation onClick={handleToggleNav} display={isNavOpen} />}
                {breadcrumbs && breadcrumbs.length > 0 && (
                    <Breadcrumbs
                        className={`${breadcrumbsBox} ${areBreadcrumbsDark ? dark : ''}`}
                        breadcrumbs={breadcrumbs}
                    />
                )}
                <div style={{ transform: `translateY(${position}vh)` }} className={scrollable}>
                    <main className={`${grid} ${className}`}>{children}</main>
                    {!isLanding && festivals.length > 0 && <FestivalList festivals={festivals} />}
                    <Footer isLanding={isLanding} />
                </div>

                {isComplete && <Cookies />}
            </div>
            <RouteChangeAnimation />
            <Intro />
        </>
    );
};

function throttleScroll(fn: (event: WheelEvent | KeyboardEvent) => void, wait: number) {
    let time = Date.now();
    return function (event: WheelEvent | KeyboardEvent) {
        if (Math.abs(('deltaY' in event && event.deltaY) || NaN) < 20) return;

        if (time + wait - Date.now() < 0) {
            fn(event);
            time = Date.now();
        }
    };
}

const query = graphql`
    query {
        #    sort: { fields: createdAt, order: ASC }
        allFestival {
            edges {
                node {
                    ...festivalFields
                }
            }
        }
    }
`;

export default MainLayout;
