import React, { useEffect, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { Variant, motion } from 'framer-motion';

import { container, buttons, acceptButton, text } from './cookies.module.scss';
import { COOKIES_ACCEPT_KEY, COOKIES_ACCEPT_VALUE } from '../../config/consts';

import CloseButton from '../atoms/close-button';
import Markdown from '../hoc/markdown';

interface ICookiesProps {
    className?: string;
}

const Cookies: React.FC<ICookiesProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        localStorage.setItem(COOKIES_ACCEPT_KEY, COOKIES_ACCEPT_VALUE);
        setShow(false);
    };

    useEffect(() => {
        const areCookiesAccepted = localStorage.getItem(COOKIES_ACCEPT_KEY);
        if (!areCookiesAccepted) {
            setShow(true);
        }
    }, []);

    return (
        <motion.div
            className={`${container} ${className}`}
            variants={variants}
            initial="hidden"
            animate={show ? 'visible' : 'hidden'}
        >
            <Markdown className={text}>{t('cookies.text')}</Markdown>
            <div className={buttons}>
                <button className={acceptButton} onClick={handleClose}>
                    {t('cookies.accept')}
                </button>
                <CloseButton onClick={handleClose} color="dark" />
            </div>
        </motion.div>
    );
};

const variants: Record<string, Variant> = {
    visible: {
        y: 0,
        transition: {
            duration: 0.4,
            delay: 1,
        },
    },
    hidden: {
        y: '100%',
        transition: {
            duration: 0.3,
        },
    },
};

export default Cookies;
