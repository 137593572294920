import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    header,
    logoLink,
    logoContainer,
    languageHamburger,
    visible,
    logo,
} from './header.module.scss';
import LogoIcon from '../../assets/images/svg/logo.svg';
import useWindowScroll from '../../hooks/use-window-scroll';

import LanguageSwitcher from '../atoms/language-switcher';
import Hamburger from '../atoms/hamburger';

interface IHeaderProps {
    className?: string;
    onClick(): void;
    isVisibleHeaderBg?: boolean;
    isLanding: boolean;
}

const Header: React.FC<IHeaderProps> = ({
    className = '',
    onClick,
    isVisibleHeaderBg,
    isLanding,
}) => {
    const { t } = useI18next();
    const { y } = useWindowScroll();
    const isScrolled = y >= 50;

    return (
        <header
            className={`
                ${className} ${header} 
                ${isVisibleHeaderBg || isScrolled ? visible : ''}
            `}
        >
            {isLanding ? (
                <div className={logoContainer}>
                    <LogoIcon className={logo} />
                </div>
            ) : (
                <Link to="/" className={logoLink} aria-label={t('nav.home')}>
                    <LogoIcon className={logo} />
                </Link>
            )}
            <div className={languageHamburger}>
                <LanguageSwitcher isVisibleHeaderBg={isVisibleHeaderBg} isScrolled={isScrolled} />
                {!isLanding && (
                    <Hamburger
                        onClick={onClick}
                        isVisibleHeaderBg={isVisibleHeaderBg}
                        isScrolled={isScrolled}
                    />
                )}
            </div>
        </header>
    );
};
export default Header;
