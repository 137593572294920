import React from 'react';

import {
    navigationContainer,
    navigationInnerContainer,
    navigationDisplay,
    navigationHide,
    navigation,
    navigationContent,
    navigationItem,
    languageCloseButton,
    invisibleButton,
    invisibleButtonColor,
} from './navigation.module.scss';
import { navLinks } from '../../config/nav-links';

import SocialIcons from '../molecules/social-icons';
import LanguageSwitcher from '../atoms/language-switcher';
import CloseButton from '../atoms/close-button';
import NavLink from '../atoms/nav-link';

interface INavigationProps {
    className?: string;
    display: boolean;
    onClick(): void;
}

const Navigation: React.FC<INavigationProps> = ({ onClick, display, className = '' }) => {
    return (
        <div
            className={`${className} ${navigationContainer} ${
                display ? navigationDisplay : navigationHide
            }`}
        >
            <button
                className={`${invisibleButton} ${display ? invisibleButtonColor : ''}`}
                onClick={onClick}
                tabIndex={-1}
            />
            <div className={navigationInnerContainer}>
                <div className={languageCloseButton}>
                    <LanguageSwitcher isDark={true} />
                    <CloseButton onClick={onClick} />
                </div>
                <div className={navigationContent}>
                    <nav className={navigation}>
                        <ul>
                            {Object.values(navLinks).map((navLink) => {
                                return (
                                    <li className={navigationItem} key={navLink.labelKey}>
                                        <NavLink navLink={navLink} onClick={onClick} />
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    <SocialIcons isDark={true} />
                </div>
            </div>
        </div>
    );
};

export default Navigation;
