export type TRelationKey =
    | 'mainImage'
    | 'secondaryImage'
    | 'coverImageHorizontal'
    | 'coverImage'
    | 'ogImage'
    | 'videoHorizontal'
    | 'videoVertical'
    | 'thumbnailHorizontal'
    | 'thumbnailVertical'
    | 'layoutImageTop'
    | 'layoutImageLeft'
    | 'layoutImageRight'
    | 'layoutImageBottom'
    | 'thumbnailBackground';

export type TRelations = Record<TRelationKey, number>;

export const relations: TRelations = {
    mainImage: 1,
    secondaryImage: 2,
    coverImageHorizontal: 3,
    coverImage: 4,
    ogImage: 6,
    videoHorizontal: 101,
    videoVertical: 102,
    thumbnailHorizontal: 103,
    thumbnailVertical: 104,
    layoutImageTop: 105,
    layoutImageLeft: 106,
    layoutImageRight: 107,
    layoutImageBottom: 108,
    thumbnailBackground: 109,
};
