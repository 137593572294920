import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { button, lineBox, line, top, bottom, dark } from './close-button.module.scss';

type TColor = 'medium' | 'dark';

interface ICloseButton {
    className?: string;
    onClick(): void;
    ariaLabel?: string;
    color?: TColor;
}

const CloseButton: React.FC<ICloseButton> = ({
    onClick,
    className = '',
    ariaLabel,
    color = 'medium',
}) => {
    const { t } = useI18next();
    const colorClass = colorClasses[color];

    return (
        <button
            onClick={onClick}
            className={`${className} ${button} ${colorClass}`}
            aria-label={ariaLabel || t('button.close')}
        >
            <span className={lineBox}>
                <span className={`${line} ${top}`} />
                <span className={`${line} ${bottom}`} />
            </span>
        </button>
    );
};

const colorClasses: Record<TColor, string> = {
    medium: '',
    dark: dark,
};

export default CloseButton;
