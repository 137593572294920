import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { Link } from 'gatsby';

import { box, text, dark } from './language-switcher.module.scss';

interface ILanguageSwitcherProps {
    className?: string;
    isDark?: boolean;
    isVisibleHeaderBg?: boolean;
    isScrolled?: boolean;
}

const LanguageSwitcher: React.FC<ILanguageSwitcherProps> = ({
    className = '',
    isDark = false,
    isVisibleHeaderBg,
    isScrolled,
}) => {
    const { languages, defaultLanguage, pagePaths, language } = useI18next();
    return (
        <div
            className={`
                ${className} 
                ${box} 
                ${isDark ? dark : ''}
                ${isVisibleHeaderBg ? dark : ''}
                ${isScrolled ? dark : ''}
            `}
        >
            {languages.map((lang) => {
                if (lang === language) return null;
                const path =
                    lang === defaultLanguage ? pagePaths[lang] : `/${lang}${pagePaths[lang]}`;
                return (
                    <Link to={path} key={`language-${lang}`} className={text}>
                        {lang}
                    </Link>
                );
            })}
        </div>
    );
};

export default LanguageSwitcher;
