import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    box,
    rightText,
    linkBottom,
    textBottom,
    socialIcon,
} from './footer-bottom.module.scss';

import SocialIcons from './social-icons';

import { grid } from '../../styles/grid.module.scss';

interface IFooterBottomProps {
    className?: string;
}

const policyLinks: Record<string, string> = {
    en: '/docs/en/privacy-policy.pdf',
    pl: '/docs/pl/polityka-prywatnosci.pdf',
};

const FooterBottom: React.FC<IFooterBottomProps> = ({ className = '' }) => {
    const { t, language } = useI18next();

    return (
        <div className={`${grid} ${container}`}>
            <div className={` ${box}`}>
                <SocialIcons isDark={false} className={socialIcon} />
                <div className={rightText}>
                    <a
                        href={policyLinks[language]}
                        target="_blank"
                        className={linkBottom}
                        rel="noreferrer"
                    >
                        {t('footer.bottom.privacy')}
                    </a>
                    <p className={textBottom}>
                        <span>{t('footer.bottom.developed')}</span>
                        <a href="https://alterpage.pl/" className={linkBottom}>
                            Alterpage.pl
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default FooterBottom;
