import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    box,
    column,
    titleBig,
    titleSmall,
    text,
    link,
    bgImage,
} from './footer-top.module.scss';
import { grid } from '../../styles/grid.module.scss';
import paperBg from '../../assets/images/paper-bg.webp';

interface IFooterTopProps {
    className?: string;
}

const FooterTop: React.FC<IFooterTopProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div
            className={`${grid} ${container} ${bgImage} ${className}`}
            style={{ backgroundImage: `url(${paperBg})` }}
        >
            <div className={`${box}`}>
                <div className={column}>
                    <h2 className={titleBig}> {t('footer.title.big1')} </h2>
                    <h2 className={titleBig}> {t('footer.title.big2')} </h2>
                </div>
                <div className={column}>
                    <h3 className={titleSmall}>{t('footer.title.column1')}</h3>
                    <a href="mailto:contact@fcbandthemilkman.pl" className={`${text} ${link}`}>
                        {t('footer.column1.mail')}
                    </a>
                    <a href="tel:+48222433496" className={`${text} ${link}`}>
                        {t('footer.column1.phone')}
                    </a>
                </div>
                <div className={column}>
                    <h3 className={titleSmall}>{t('footer.title.column2')}</h3>
                    <a href="mailto:join@fcbandthemilkman.pl" className={`${text} ${link}`}>
                        {t('footer.column2.mail')}
                    </a>
                    <a href="tel:+48222433496" className={`${text} ${link}`}>
                        {t('footer.column2.phone')}
                    </a>
                </div>
                <div className={column}>
                    <h3 className={titleSmall}>{t('footer.title.column3')}</h3>
                    <p className={text}>{t('footer.column3.street')}</p>
                    <p className={text}>{t('footer.column3.city')}</p>
                </div>
            </div>
        </div>
    );
};

export default FooterTop;
