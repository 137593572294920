import { INavLink } from '../models/nav-link.model';

export const navLinks: Record<string, INavLink> = {
    home: {
        slug: '/',
        urlIdKey: '',
        labelKey: 'nav.home',
    },
    works: {
        slug: 'projekty',
        urlIdKey: 'nav.id.work',
        labelKey: 'nav.work',
    },
    whyUs: {
        slug: '/',
        urlIdKey: 'nav.id.why',
        labelKey: 'nav.why',
    },
    about: {
        slug: '/',
        urlIdKey: 'nav.id.who',
        labelKey: 'nav.who',
    },
    news: {
        slug: 'news',
        urlIdKey: 'nav.id.news',
        labelKey: 'nav.news',
    },
    contact: {
        slug: '/',
        urlIdKey: 'nav.id.contact',
        labelKey: 'nav.contact',
    },
};
