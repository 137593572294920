import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { link, itemText, textHidden } from './nav-link.module.scss';
import { INavLink } from '../../models/nav-link.model';
import { useLocation } from '@reach/router';

interface INavLinkProps {
    className?: string;
    onClick?(): void;
    navLink: INavLink;
}

const NavLink: React.FC<INavLinkProps> = ({ className = '', navLink, onClick }) => {
    const { pathname } = useLocation();
    const { t, language, defaultLanguage } = useI18next();
    const { slug, labelKey, urlIdKey } = navLink;

    const isHome = isHomePage(pathname, language, defaultLanguage);
    const linkSlug = isHome ? '/' : slug;
    const includeUrlId = isHome || slug === '/';

    return (
        <Link
            to={linkSlug}
            className={`${className} ${link}`}
            onClick={onClick}
            {...(includeUrlId ? { urlId: t(urlIdKey) } : {})}
        >
            <span className={itemText}>{t(labelKey)}</span>
            <span className={textHidden}>{t(labelKey)}</span>
        </Link>
    );
};

function isHomePage(pathname: string, language: string, defaultLanguage: string): boolean {
    if (language === defaultLanguage) {
        return pathname === '/';
    }
    return pathname === `/${language}/`;
}

export default NavLink;
