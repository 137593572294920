import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { socialIcons, list } from './social-icons.module.scss';
import { ISocial } from '../../models/social.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { getNodes } from '../../utils/get-nodes';

import SocialIcon from '../atoms/social-icon';

interface ISocialIconsProps {
    className?: string;
    isDark?: boolean;
}

interface ISocialIconsQueryResult {
    allSocial: IQueryAllResult<ISocial>;
}

const SocialIcons: React.FC<ISocialIconsProps> = ({ className = '', isDark = false }) => {
    const { allSocial }: ISocialIconsQueryResult = useStaticQuery(query);
    const socials = getNodes(allSocial);
    return (
        <div className={`${className} ${socialIcons}`}>
            <ul className={list}>
                {socials.map((social) => {
                    return (
                        <li key={`social-${social.socialType}`}>
                            <SocialIcon social={social} isDark={isDark} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

const query = graphql`
    query {
        allSocial(sort: { fields: socialType, order: ASC }) {
            edges {
                node {
                    ...socialFields
                }
            }
        }
    }
`;

export default SocialIcons;
