import React from 'react';
import { motion, Variant } from 'framer-motion';

import { container } from './route-change-animation.module.scss';

interface IRouteChangeAnimationProps {
    className?: string;
}

const ANIMATION_DURATION = 0.7;

const RouteChangeAnimation: React.FC<IRouteChangeAnimationProps> = ({ className = '' }) => {
    return (
        <motion.div
            variants={variants}
            initial="initial"
            animate="hidden"
            exit="exit"
            className={`${container} ${className}`}
        />
    );
};

const variants: Record<string, Variant> = {
    initial: {
        y: '0%',
        transition: {
            duration: ANIMATION_DURATION,
        },
    },
    hidden: {
        y: ['0%', '-100%'],
        transition: {
            duration: ANIMATION_DURATION,
        },
    },
    exit: {
        y: ['100%', '0%'],
        transition: {
            duration: ANIMATION_DURATION,
        },
    },
};

export default RouteChangeAnimation;
