import React from 'react';

import { footer } from './footer.module.scss';

import FooterTop from '../molecules/footer-top';
import FooterBottom from '../molecules/footer-bottom';

interface IFooterProps {
    className?: string;
    isLanding: boolean;
}

const Footer: React.FC<IFooterProps> = ({ className = '', isLanding }) => {
    return (
        <footer className={` ${className} ${footer} `}>
            {!isLanding && <FooterTop />}
            <FooterBottom />
        </footer>
    );
};

export default Footer;
