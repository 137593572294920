import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { dark, socialIcon } from './social-icon.module.scss';
import IconFb from '../../assets/images/svg/icon-fb.svg';
import IconIg from '../../assets/images/svg/icon-ig.svg';
import IconLi from '../../assets/images/svg/icon-li.svg';
import IconYt from '../../assets/images/svg/icon-yt.svg';
import { ESocialType, ISocial } from '../../models/social.model';

interface ISocialIconProps {
    className?: string;
    isDark?: boolean;
    social: ISocial;
}

type TSocialIconMap = Record<ISocial['socialType'], React.SVGFactory>;

const SocialIcon: React.FC<ISocialIconProps> = ({ className = '', isDark = false, social }) => {
    const { t } = useI18next();
    const { url, socialType } = social;
    const Icon = socialIconMap[socialType];
    return (
        <a
            href={url}
            target="_blank"
            rel="noreferrer noopener"
            className={` ${socialIcon} ${isDark ? dark : ''} ${className}`}
            aria-label={t(`social.${ESocialType[socialType]}`)}
        >
            <Icon />
        </a>
    );
};

const socialIconMap: TSocialIconMap = {
    [ESocialType.facebook]: IconFb,
    [ESocialType.youtube]: IconYt,
    [ESocialType.instagram]: IconIg,
    [ESocialType.linkedin]: IconLi,
};

export default SocialIcon;
