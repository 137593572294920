import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { container, list, item } from './breadcrumbs.module.scss';
import { IBreadcrumb } from '../../models/breadcrumb.model';
import { ISitePage } from '../../config/site-page.model';
import { getBreadcrumbItem } from '../../utils/get-breadcrumbs';

import Breadcrumb from '../atoms/breadcrumb';

interface IBreadcrumbsProps {
    className?: string;
    breadcrumbs: IBreadcrumb[];
}

interface IBreadcrumbsQueryResult {
    homePage: ISitePage;
}

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ className = '', breadcrumbs }) => {
    const {
        homePage: { pageContext },
    }: IBreadcrumbsQueryResult = useStaticQuery(query);

    const homeBreadcrumb: IBreadcrumb = getBreadcrumbItem(pageContext);
    const breadcrumbsWithHome = [homeBreadcrumb, ...breadcrumbs];

    return (
        <nav className={`${className} ${container}`}>
            <ol className={list}>
                {breadcrumbsWithHome.map((breadcrumb, index) => {
                    return (
                        <li key={`breadcrumb-${breadcrumb.slug}`} className={item}>
                            <Breadcrumb
                                breadcrumb={breadcrumb}
                                isLink={index < breadcrumbsWithHome.length - 1}
                            />
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};

const query = graphql`
    query {
        homePage: sitePage(path: { eq: "/" }) {
            pageContext
        }
    }
`;

export default Breadcrumbs;
