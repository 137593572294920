// extracted by mini-css-extract-plugin
export var navigationContainer = "navigation-module--navigation-container--tvKSR";
export var navigationInnerContainer = "navigation-module--navigation-inner-container--5Axt6";
export var navigationHide = "navigation-module--navigation-hide--Al5Pe";
export var navigationDisplay = "navigation-module--navigation-display--pD0JB";
export var navigationContent = "navigation-module--navigation-content--2uIyc";
export var navigation = "navigation-module--navigation--mA8Da";
export var navigationItem = "navigation-module--navigation-item--cQC30";
export var languageCloseButton = "navigation-module--language-close-button--aRohk";
export var invisibleButton = "navigation-module--invisible-button--Osmr3";
export var invisibleButtonColor = "navigation-module--invisible-button-color--k0mJg";